<template>
  <CreateUpdateTemplate
    :customClass="'task-create'"
    v-if="getPermission('task:create')"
  >
    <template v-slot:header-title>
      <h1 class="form-title d-flex">Create new Task</h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-on:click="goBack"
        :disabled="formLoading || pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        v-on:click="updateOrCreate"
        color="cyan"
      >
        Save Task
      </v-btn>
      <v-menu
        content-class="custom-menu-list"
        transition="slide-y-transition"
        bottom
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :disabled="!formValid || formLoading || pageLoading"
            :loading="formLoading"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            v-bind="attrs"
            v-on="on"
          >
            Save and... <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item link v-on:click="updateOrCreate('mark_as_testing')">
            <v-list-item-title class="font-weight-500 font-size-14"
              >Mark as Testing</v-list-item-title
            >
          </v-list-item>
          <v-list-item link v-on:click="updateOrCreate('mark_as_inprogress')">
            <v-list-item-title class="font-weight-500 font-size-14"
              >Mark as In-Progress</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <template v-slot:body>
      <v-form
        ref="taskForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="updateOrCreate"
      >
        <v-container fluid>
          <!-- <perfect-scrollbar
            :options="{ suppressScrollX: true }"
            class="scroll custom-box-top-inner-shadow"
            style="max-height: 70vh; position: relative"
          > -->
          <v-row>
            <v-col md="7">
              <v-row>
                <v-col md="12" class="py-0">
                  <label class="font-weight-700 font-size-16"
                    >Task Subject</label
                  >
                  <v-text-field
                    v-model.trim="taskCreate.subject"
                    dense
                    filled
                    label="Subject"
                    solo
                    flat
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    color="cyan"
                    :rules="[
                      validateRules.required(
                        taskCreate.subject,
                        'Task Subject'
                      ),
                      validateRules.minLength(
                        taskCreate.subject,
                        'Task Subject',
                        1
                      ),
                      validateRules.maxLength(
                        taskCreate.subject,
                        'Task Subject',
                        100
                      )
                    ]"
                  ></v-text-field>
                </v-col>
                <v-col md="12" class="py-0">
                  <editor v-model="taskCreate.description" />
                </v-col>
              </v-row>
            </v-col>
            <v-col md="5">
              <table class="width-100">
                <tr>
                  <td colspan="3" class="font-weight-700 font-size-16 pb-2">
                    Task details
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Task number</td>
                  <td class="font-weight-700 font-size-16 pb-2" width="150">
                    <v-skeleton-loader
                      v-if="pageLoading"
                      class="custom-skeleton"
                      type="text"
                    ></v-skeleton-loader>
                    <template v-else>{{ taskCreate.barcode }}</template>
                  </td>
                  <td class="font-size-16 pb-2">
                    <v-btn
                      text
                      small
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      class="mx-2 custom-bold-button"
                      v-on:click="barcodeDialog = true"
                      color="cyan"
                    >
                      Change
                    </v-btn>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Reference #</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Reference #"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-text-field
                      dense
                      color="cyan"
                      filled
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model.trim="taskCreate.reference"
                      label="Reference #"
                      :rules="[
                        validateRules.minLength(
                          taskCreate.reference,
                          'Reference',
                          1
                        ),
                        validateRules.maxLength(
                          taskCreate.reference,
                          'Reference',
                          100
                        )
                      ]"
                      solo
                      flat
                      hide-details
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Hourly Rate</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Hourly Rate"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-text-field
                      v-model.number="taskCreate.hourly_rate"
                      dense
                      filled
                      type="number"
                      min="0"
                      max="999"
                      label="Hourly Rate"
                      solo
                      flat
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                      v-on:keypress="
                        limitDecimal($event, taskCreate.hourly_rate)
                      "
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Priority</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Priority"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-autocomplete
                      v-model.trim="taskCreate.priority"
                      :items="taskPriorityList"
                      dense
                      filled
                      :disabled="pageLoading"
                      color="cyan"
                      item-color="cyan"
                      item-text="value"
                      item-value="id"
                      label="Priority"
                      solo
                      flat
                      return-object
                      hide-details
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Priority Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Start Date</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Start Date"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <DatePicker
                      :defaultDate.sync="defaultStartDate"
                      :pageLoading.sync="pageLoading"
                      solo
                      :placeholder="'Start Date'"
                      v-on:update:date-picker="setStartDate"
                    ></DatePicker>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Due Date</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Due Date"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <DatePicker
                      :defaultDate.sync="defaultDueDate"
                      :pageLoading.sync="pageLoading"
                      solo
                      placeholder="Due Date"
                      v-on:update:date-picker="setDueDate"
                    ></DatePicker>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Repeat every</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Recurrence"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-select
                      v-model.trim="taskCreate.recurrence"
                      :items="taskRecurrenceList"
                      dense
                      filled
                      :disabled="pageLoading"
                      color="cyan"
                      item-color="cyan"
                      item-text="value"
                      item-value="id"
                      label="Recurrence"
                      solo
                      flat
                      return-object
                      hide-details
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Recurrence Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </td>
                </tr>
                <template v-if="taskCreate.recurrence">
                  <tr v-if="taskCreate.recurrence.custom">
                    <td class="font-size-16 pb-2" width="200">&nbsp;</td>
                    <td
                      colspan="2"
                      class="pb-4"
                      content="Custom Recurrence"
                      v-tippy="{ placement: 'top-start' }"
                    >
                      <v-layout>
                        <v-flex>
                          <v-text-field
                            v-model.number="taskCreate.duration"
                            dense
                            filled
                            type="number"
                            min="0"
                            max="999"
                            label="Duration"
                            solo
                            flat
                            hide-details
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            color="cyan"
                          ></v-text-field>
                        </v-flex>
                        <v-flex>
                          <v-select
                            v-model.trim="taskCreate.duration_type"
                            :items="taskDurationTypeList"
                            dense
                            filled
                            :disabled="pageLoading"
                            color="cyan"
                            item-color="cyan"
                            item-text="value"
                            item-value="id"
                            label="Duration Type"
                            solo
                            flat
                            return-object
                            hide-details
                          >
                            <template v-slot:no-data>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title
                                    v-html="'No Duration Type Found.'"
                                  ></v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                          </v-select>
                        </v-flex>
                      </v-layout>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-size-16 pb-2" width="200">Total Cycles</td>
                    <td
                      colspan="2"
                      class="pb-4"
                      content="Total Cycles"
                      v-tippy="{ placement: 'top-start' }"
                    >
                      <v-text-field
                        v-model.number="taskCreate.total_cycle"
                        dense
                        filled
                        type="number"
                        min="0"
                        max="30"
                        v-mask="'##'"
                        label="Total Cycles"
                        solo
                        flat
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-text-field>
                    </td>
                  </tr>
                </template>
              </table>
            </v-col>
            <v-col md="12">
              <FileTemplate
                allowUpload
                v-on:file:updated="updateAttachment"
              ></FileTemplate>
            </v-col>
          </v-row>
          <!-- </perfect-scrollbar> -->
        </v-container>
      </v-form>
    </template>
    <template v-slot:barcode-setting>
      <BarcodeSetting
        endpoint="task"
        :barcodeDialog="barcodeDialog"
        :dialogWidth="600"
        :barcodeSetting.sync="barcodeSetting"
        v-on:update:barcode-setting="getOptions"
        v-on:close:barcode-dialog="barcodeDialog = false"
      ></BarcodeSetting>
    </template>
  </CreateUpdateTemplate>
</template>
<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import BarcodeSetting from "@/view/pages/partials/Barcode-Setting.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { CLEAR_ERROR, POST, GET } from "@/core/services/store/request.module";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import FileTemplate from "@/view/pages/partials/FileTemplate";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "task-create",
  title: "Create Task",
  data() {
    return {
      pageLoading: false,
      barcodeDialog: false,
      barcodeSetting: new Object(),
      taskDuplicate: 0,
      taskCreate: new Object({
        project: null,
        subject: null,
        description: null,
        barcode: null,
        reference: null,
        hourly_rate: 0,
        priority: null,
        recurrence: null,
        duration: null,
        duration_type: null,
        total_cycle: null,
        start_date: null,
        attachments: null,
        due_date: null
      }),
      taskPriorityList: [
        {
          id: 1,
          value: "High"
        },
        {
          id: 2,
          value: "Medium"
        },
        {
          id: 3,
          value: "Low"
        },
        {
          id: 0,
          value: "None"
        }
      ],
      taskDurationTypeList: [
        {
          id: 1,
          value: "Day(s)"
        },
        {
          id: 2,
          value: "Week(s)"
        },
        {
          id: 3,
          value: "Month(s)"
        },
        {
          id: 4,
          value: "Year(s)"
        }
      ],
      taskRecurrenceList: [
        {
          id: 1,
          custom: false,
          value: "Week"
        },
        {
          id: 2,
          custom: false,
          value: "2 Weeks"
        },
        {
          id: 3,
          custom: false,
          value: "1 Months"
        },
        {
          id: 4,
          custom: false,
          value: "2 Months"
        },
        {
          id: 5,
          custom: false,
          value: "3 Months"
        },
        {
          id: 6,
          custom: false,
          value: "6 Months"
        },
        {
          id: 7,
          custom: false,
          value: "1 Year"
        },
        {
          id: 8,
          custom: true,
          value: "Custom"
        }
      ]
    };
  },
  components: {
    DatePicker,
    FileTemplate,
    BarcodeSetting,
    CreateUpdateTemplate,
    editor: TinyMCE
  },
  methods: {
    updateAttachment(param) {
      this.taskCreate.attachments = param;
    },
    getOptions() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "tasks/options"
        })
        .then(({ data }) => {
          _this.barcodeSetting = data.barcode_setting;
          _this.taskCreate.barcode = data.barcode;
        })
        .catch(error => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateOrCreate(action) {
      const _this = this;

      const validateStatus = _this.$refs.taskForm.validate();

      const formErrors = _this.validateForm(_this.$refs.taskForm);
      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      if (!validateStatus) {
        return false;
      }

      let status = 4;
      if (action == "mark_as_testing") {
        status = 2;
      }
      if (action == "mark_as_inprogress") {
        status = 3;
      }

      _this.formLoading = true;
      _this.$store.dispatch(CLEAR_ERROR, {});

      let formData = new Object({
        reference: _this.taskCreate.reference,
        subject: _this.taskCreate.subject,
        project: _this.taskCreate.project,
        description: _this.taskCreate.description,
        hourly_rate: _this.taskCreate.hourly_rate,
        start_date: _this.taskCreate.start_date,
        due_date: _this.taskCreate.due_date,
        recurrence: _this.taskCreate.recurrence
          ? _this.taskCreate.recurrence.id
          : null,
        duration:
          _this.taskCreate.recurrence && _this.taskCreate.recurrence.custom
            ? _this.taskCreate.duration
            : null,
        duration_type:
          _this.taskCreate.recurrence && _this.taskCreate.recurrence.custom
            ? _this.taskCreate.duration_type
              ? _this.taskCreate.duration_type.id
              : null
            : null,
        total_cycle: _this.taskCreate.total_cycle,
        status: status,
        priority: _this.taskCreate.priority
          ? _this.taskCreate.priority.id
          : null,
        attachments: _this.taskCreate.attachments
      });

      _this.$store
        .dispatch(POST, {
          url: "tasks",
          data: formData
        })
        .then(({ data }) => {
          _this.$router.push(
            _this.getDefaultRoute("task.detail", {
              params: {
                id: data.id
              }
            })
          );
        })
        .catch(error => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    setStartDate(param) {
      this.taskCreate.start_date = param;
    },
    setDueDate(param) {
      this.taskCreate.due_date = param;
    },
    getTask() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "tasks/" + _this.taskDuplicate
        })
        .then(({ data }) => {
          _this.taskCreate.subject = data.subject;
          _this.taskCreate.description = data.description;
          _this.taskCreate.reference = data.reference;
          _this.taskCreate.hourly_rate = data.hourly_rate;
          _this.taskCreate.priority = data.priority;
          _this.taskCreate.recurrence = data.recurrence;
          _this.taskCreate.duration = data.duration;
          _this.taskCreate.duration_type = data.duration_type;
          _this.taskCreate.total_cycle = data.total_cycle;
          _this.taskCreate.start_date = data.start_date;
          _this.taskCreate.attachments = [];
          _this.taskCreate.due_date = data.due_date;
        })
        .catch(error => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    }
  },
  mounted() {
    const _this = this;
    _this.getOptions();
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Task", route: "task" },
      { title: "Create" }
    ]);

    _this.taskDuplicate = _this.lodash.toSafeInteger(
      _this.$route.query.duplicate
    );

    _this.taskCreate.project =
      _this.lodash.toSafeInteger(_this.$route.query.project) || null;

    if (_this.taskDuplicate > 0) {
      _this.getTask();
    }
  },
  computed: {
    defaultStartDate() {
      if (this.taskCreate.start_date) {
        return this.taskCreate.start_date;
      }
      return new Date().toISOString().substr(0, 10);
    },
    defaultDueDate() {
      if (this.taskCreate.due_date) {
        return this.taskCreate.due_date;
      }
      let today = new Date();
      today.setDate(today.getDate() + 30);
      return today.toISOString().substr(0, 10);
    }
  }
};
</script>
